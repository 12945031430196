<template>
  <div id="data-view">
    <dv-full-screen-container id="dv-full-screen-container">
      <dv-border-box-1 class="d-flex w-100p">
        <div class="w-100p pa-4 d-flex justify-space-between">

          <!--     供给端     -->
          <dv-border-box-13 v-if="true" class="pa-4" style="width: 480px">
            <div class="w-100p overflow-y-auto">

              <div class="w-100p d-flex justify-center">
                <dv-decoration-11 style="width:200px;height:60px;">
                  供给端数据
                </dv-decoration-11>
              </div>

              <div v-for="item in supplySide" :v-key="item.key">
                <dv-border-box-12>
                  <div class="pa-2 w-100p">
                    <div class="">{{ item.title }}</div>

                    <div class="d-flex">
                      <div v-for="chart in item.charts" class="w-100p">
                        <div class="caption">{{ chart.title }}</div>
                        <dv-active-ring-chart v-if="chart.chartType==='pie'"
                                              :config="chart.config"
                                              style="width:150px;height:150px"
                        />
                        <dv-capsule-chart v-else-if="chart.chartType==='capsuleChart'"
                                          :config="chart.config"
                                          style="width:290px;height:150px"
                        />
                        <dv-scroll-board v-else-if="chart.chartType==='scrollBoard'"
                                         :config="chart.config"
                                         style="height:200px"
                        />
                        <dv-charts v-else :option="chart.config" style="height:200px"/>
                      </div>
                    </div>

                  </div>
                </dv-border-box-12>
              </div>
            </div>
          </dv-border-box-13>

          <!--     预警     -->
          <dv-border-box-13 class="pa-4 mx-4">
            <div class="w-100p overflow-y-auto d-flex flex-column justify-space-between">

              <div class="w-100p d-flex justify-center">
                <dv-decoration-11 style="width:200px;height:60px;">
                  健康热力图
                </dv-decoration-11>
              </div>

              <dv-border-box-12>
                <div class="flex-shrink-0 overflow-hidden pa-4" style="width: 100%; height: 360px">
                  <hot-map/>
                </div>
              </dv-border-box-12>

              <div v-if="true" class="w-100p">

                <div class="w-100p d-flex justify-center">
                  <dv-decoration-11 style="width:200px;height:60px;">
                    决策支持
                  </dv-decoration-11>
                </div>

                <div class="d-flex w-100p" style="width:860px">
                  <div v-for="item in decisionSupports" :v-key="item.key" class="flex-grow-1">
                    <dv-border-box-12>
                      <div class="pa-2 w-100p">
                        <div class="">{{ item.title }}</div>

                        <div class="d-flex flex-grow-1 justify-space-between">
                          <div v-for="chart in item.charts">
                            <div class="caption">{{ chart.title }}</div>
                            <div v-if="chart.config.data && !chart.config.data.length" class="grey--text">无</div>
                            <template v-else>
                              <dv-active-ring-chart v-if="chart.chartType==='pie'"
                                                    :config="chart.config"
                                                    style="width:150px;height:150px"
                              />
                              <dv-capsule-chart v-else-if="chart.chartType==='capsuleChart'"
                                                :config="chart.config"
                                                style="width:290px;height:150px"
                              />
                              <dv-scroll-board v-else-if="chart.chartType==='scrollBoard'"
                                               :config="chart.config"
                                               style="width: 360px;height:200px"
                              />
                              <dv-charts v-else :option="chart.config" style="height:200px;width: 200px"/>
                            </template>
                          </div>
                        </div>

                      </div>
                    </dv-border-box-12>
                  </div>
                </div>
              </div>

              <div v-if="true" class="w-100p">

                <div class="w-100p d-flex justify-center">
                  <dv-decoration-11 style="width:200px;height:60px;">
                    预警/待办事项
                  </dv-decoration-11>
                </div>

                <div class="d-flex w-100p" style="width:860px">
                  <div v-for="item in warningEvents" :v-key="item.key" class="flex-grow-1">
                    <dv-border-box-12>
                      <div class="pa-2 w-100p">
                        <div class="">{{ item.title }}</div>

                        <div class="d-flex flex-grow-1 justify-space-between">
                          <div v-for="chart in item.charts">
                            <div class="caption">{{ chart.title }}</div>
                            <div v-if="chart.config.data && !chart.config.data.length" class="grey--text">无</div>
                            <template v-else>
                              <dv-active-ring-chart v-if="chart.chartType==='pie'"
                                                    :config="chart.config"
                                                    style="width:150px;height:150px"
                              />
                              <dv-capsule-chart v-else-if="chart.chartType==='capsuleChart'"
                                                :config="chart.config"
                                                style="width:290px;height:150px"
                              />
                              <dv-scroll-board v-else-if="chart.chartType==='scrollBoard'"
                                               :config="chart.config"
                                               style="width: 640px;height:160px"
                              />
                              <dv-charts v-else :option="chart.config" style="height:200px;width: 360px"/>
                            </template>
                          </div>
                        </div>

                      </div>
                    </dv-border-box-12>
                  </div>
                </div>
              </div>

              <!--     待办     -->
              <div v-if="true" class="w-100p">
                <div class="d-flex w-100p" style="width:860px">
                  <div v-for="item in todoEvents" :v-key="item.key" class="flex-grow-1">
                    <dv-border-box-12>
                      <div class="pa-2 w-100p">
                        <div class="">{{ item.title }}</div>

                        <div class="d-flex flex-grow-1 justify-space-between">
                          <div v-for="chart in item.charts" class="">
                            <div class="caption">{{ chart.title }}</div>
                            <div v-if="chart.config.data && !chart.config.data.length" class="grey--text">无</div>
                            <template v-else>
                              <dv-active-ring-chart v-if="chart.chartType==='pie'"
                                                    :config="chart.config"
                                                    style="width:150px;height:150px"
                              />
                              <dv-capsule-chart v-else-if="chart.chartType==='capsuleChart'"
                                                :config="chart.config"
                                                style="width:290px;height:150px"
                              />
                              <dv-scroll-board v-else-if="chart.chartType==='scrollBoard'"
                                               :config="chart.config"
                                               style="width: 320px;height:200px"
                              />
                              <dv-charts v-else :option="chart.config" style="height:200px;width: 360px"/>
                            </template>
                          </div>
                        </div>

                      </div>
                    </dv-border-box-12>
                  </div>
                </div>
              </div>
            </div>
          </dv-border-box-13>

          <!--     需求端     -->
          <dv-border-box-13 v-if="true" class="pa-4" style="width: 480px">
            <div class="w-100p overflow-y-auto">

              <div class="w-100p d-flex justify-center">
                <dv-decoration-11 style="width:200px;height:60px;">
                  需求端数据
                </dv-decoration-11>
              </div>

              <div v-for="item in demandSide" :v-key="item.key">
                <dv-border-box-12>
                  <div class="pa-2 w-100p">
                    <div class="">{{ item.title }}</div>

                    <div class="d-flex">
                      <div v-for="chart in item.charts" class="w-100p">
                        <div class="caption">{{ chart.title }}</div>
                        <dv-active-ring-chart v-if="chart.chartType==='pie'"
                                              :config="chart.config"
                                              style="width:150px;height:150px"
                        />
                        <dv-capsule-chart v-else-if="chart.chartType==='capsuleChart'"
                                          :config="chart.config"
                                          style="width:290px;height:150px"
                        />
                        <dv-scroll-board v-else-if="chart.chartType==='scrollBoard'"
                                         :config="chart.config"
                                         style="height:200px"
                        />
                        <dv-charts v-else :option="chart.config" style="height:200px"/>
                      </div>
                    </div>

                  </div>
                </dv-border-box-12>
              </div>
            </div>

          </dv-border-box-13>
        </div>
      </dv-border-box-1>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Data from '../utils/DataVisualization'
import HotMap from "../components/HotMap";

export default {
  name: 'Visualization',
  components: {HotMap},
  data() {
    return {
      supplySide: Data.supplySide,
      demandSide: Data.demandSide,
      warningEvents: Data.warningEvents,
      todoEvents: Data.todoEvents,
      decisionSupports: Data.decisionSupports
    }
  },
}
</script>

<style>
#data-view {
  width: 100%;
  height: 100%;
  background-color: #102030;
  color: #fff;
}

#dv-full-screen-container {
  background-size: 100% 100%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
}

.main-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.mh-left {
  font-size: 20px;
  color: rgb(1, 134, 187);
}

.mh-middle {
  font-size: 30px;
}

.mh-left, .mh-right {
  width: 450px;
}


.main-container {
}

.mc-top, .mc-bottom {
  box-sizing: border-box;
  padding: 30px;
  display: flex;
}

.mc-top {
  height: 40%;
}

.mc-bottom {
  height: 60%;
}

.top-left-cmp, .bottom-left-container {
  width: 32%;
}

.top-middle-cmp, .top-right-cmp {
  width: 34%;
}

.bottom-left-container {
  position: relative;
}

.border-box-content {
  display: flex;
}

.mcb-decoration-1, .mcb-decoration-2 {
  position: absolute;
  left: 50%;
  margin-left: -2px;
}

.mcb-decoration-1 {
  top: 5%;
  transform: rotate(180deg);
}

.mcb-decoration-2 {
  top: 50%;
}

.bottom-left-chart-1, .bottom-left-chart-2 {
  width: 50%;
  height: 100%;
}


.bottom-right-container {
  width: 68%;
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
}


</style>