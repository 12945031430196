<template>
  <div
      class="w-100v h-100v d-flex flex-column justify-center align-center"
  >
    <v-img src="@/assets/logo-512.svg" contain max-height="64" class="mb-4"/>
    <span class=" text-h5 font-weight-light">北京三子健康科技有限公司</span>

    <a class="fixed caption grey--text" style="bottom: 8px" href="https://beian.miit.gov.cn/" target="_blank">
      京ICP备18039183号-1
    </a>
  </div>
</template>

<script>
import EntitySeverityModifier from "../components/EntitySeverityModifier";
export default {
  name: "Home",
  components: {EntitySeverityModifier}
}
</script>

<style scoped>

</style>