const institutes = [
    {key: '', name: '中医门诊部', value: 0},
    {key: '', name: '中医诊所', value: 0},
    {key: '', name: '艾灸馆', value: 0},
    {key: '', name: '推拿按摩', value: 0},
    {key: '', name: '药店', value: 0},
    {key: '', name: '其他', value: 0},
]

const specialists = [
    {key: '', name: '执业医师', value: 0},
    {key: '', name: '执业药师', value: 0},
    {key: '', name: '执业护士', value: 0},
    {key: '', name: '推拿师', value: 0},
    {key: '', name: '按摩师', value: 0},
    {key: '', name: '艾灸师', value: 0},
    {key: '', name: '营养师', value: 0},
    {key: '', name: '健康管理师', value: 0},
    {key: '', name: '健康管家', value: 0},
    {key: '', name: '其他从业', value: 0},
]

const serviceProductsByCategories = [
    {key: '', name: '体质调节', value: 0},
    {key: '', name: '辨证论治', value: 0},
    {key: '', name: '中药处方', value: 0},
    {key: '', name: '药膳处方', value: 0},
    {key: '', name: '饮食方案', value: 0},
    {key: '', name: '运动方案', value: 0},
    {key: '', name: '推拿正骨', value: 0},
    {key: '', name: '艾灸', value: 0},
    {key: '', name: '按摩', value: 0},
    {key: '', name: '足浴', value: 0},
    {key: '', name: '音乐疗法', value: 0},
    {key: '', name: '其他', value: 0},
]

const serviceProductsBySymptomsAndSyndromes = [
    {key: '', name: '阳虚证', value: 0},
    {key: '', name: '寒湿证', value: 0},
    {key: '', name: '气虚体质', value: 0},
    {key: '', name: '肝气瘀滞证', value: 0},
    {key: '', name: '颈肩痛', value: 0},
    {key: '', name: '失眠', value: 0},
    {key: '', name: '外感诸症', value: 0},
    {key: '', name: '头痛', value: 0},
    {key: '', name: '大便溏泄', value: 0},
    {key: '', name: '情志不遂', value: 0},
    {key: '', name: '其他', value: 0},
]

const serviceProductsByDiseases = [
    {key: '', name: '高血压', value: 0},
    {key: '', name: '糖尿病', value: 0},
    {key: '', name: '冠心病', value: 0},
    {key: '', name: '颈椎病', value: 0},
    {key: '', name: '感冒', value: 0},
    {key: '', name: '更年期综合征', value: 0},
    {key: '', name: '其他', value: 0},
]

const physicalProductsByCategories = [
    {key: '', name: '预制食品', value: 0},
    {key: '', name: '饮品', value: 0},
    {key: '', name: '食材配料', value: 0},
    {key: '', name: '按摩用具', value: 0},
    {key: '', name: '艾灸用具', value: 0},
    {key: '', name: '其他', value: 0},
]

const physicalProductsBySymptomsAndSyndromes = [
    {key: '', name: '阳虚证', value: 0},
    {key: '', name: '寒湿证', value: 0},
    {key: '', name: '气虚体质', value: 0},
    {key: '', name: '肝气瘀滞证', value: 0},
    {key: '', name: '颈肩痛', value: 0},
    {key: '', name: '失眠', value: 0},
    {key: '', name: '外感诸症', value: 0},
    {key: '', name: '头痛', value: 0},
    {key: '', name: '大便溏泄', value: 0},
    {key: '', name: '情志不遂', value: 0},
    {key: '', name: '其他', value: 0},
]

const physicalProductsByDiseases = [
    {key: '', name: '高血压', value: 0},
    {key: '', name: '糖尿病', value: 0},
    {key: '', name: '冠心病', value: 0},
    {key: '', name: '颈椎病', value: 0},
    {key: '', name: '感冒', value: 0},
    {key: '', name: '更年期综合征', value: 0},
    {key: '', name: '其他', value: 0},
]

const customersByGender = [
    {key: '', name: '男', value: 0},
    {key: '', name: '女', value: 0},
    {key: '', name: 'X', value: 0},
]

const customersByAge = [
    {key: '', name: '0~6', value: 0},
    {key: '', name: '6~12', value: 0},
    {key: '', name: '12~18', value: 0},
    {key: '', name: '18~35', value: 0},
    {key: '', name: '35~45', value: 0},
    {key: '', name: '45~60', value: 0},
    {key: '', name: '60~70', value: 0},
    {key: '', name: '70+', value: 0},
]

const customersByCons = [
    {key: '', name: '平和质', value: 0},
    {key: '', name: '特禀质', value: 0},
    {key: '', name: '气虚质', value: 0},
    {key: '', name: '阳虚质', value: 0},
    {key: '', name: '阴虚质', value: 0},
    {key: '', name: '痰湿质', value: 0},
    {key: '', name: '湿热质', value: 0},
    {key: '', name: '血瘀质', value: 0},
]

const customersBySyndromes = `阴血不足
肝阳上亢
肝气郁结
肝风内动
痰热
肝胃不和
血瘀
肝肾阴亏
肝郁气逆
风痰
血热
内风
血虚
阴虚火旺
湿热
湿重于热
脾虚不运
血亏
寒湿
湿阻
肝郁
脾肾阳虚`.split('\n').map(e => ({name: e, value: 0}))

const customersBySymptoms = [
    {"name": "腰痛", "value": 0}, {"name": "头晕", "value": 0}, {
        "name": "牙痛",
        "value": 0
    }, {"name": "痛经", "value": 0}, {"name": "风团", "value": 0}, {"name": "咽部异物感", "value": 0}, {
        "name": "胸闷",
        "value": 0
    }, {"name": "腹痛", "value": 0}, {"name": "腰膝酸软", "value": 0}, {"name": "嗳气", "value": 0}, {
        "name": "便血",
        "value": 0
    }, {"name": "关节痛", "value": 0}, {"name": "腰酸", "value": 0}, {"name": "下肢发凉", "value": 0}, {
        "name": "怕冷",
        "value": 0
    }, {"name": "下腹疼痛", "value": 0}, {"name": "面部白斑", "value": 0}, {"name": "头部震颤", "value": 0}, {
        "name": "眩晕",
        "value": 0
    }, {"name": "哮喘", "value": 0}, {"name": "肩背痛", "value": 0}, {"name": "手部脱皮", "value": 0}, {
        "name": "月经稀少",
        "value": 0
    }, {"name": "腰腿痛", "value": 0}, {"name": "肩关节痛", "value": 0}, {"name": "痰多", "value": 0}, {
        "name": "月经失调",
        "value": 0
    }, {"name": "白带异常", "value": 0}, {"name": "经期推迟", "value": 0}, {"name": "腹泻", "value": 0}, {
        "name": "消瘦",
        "value": 0
    }, {"name": "反酸", "value": 0}, {"name": "淋巴结肿大", "value": 0}, {"name": "高血脂", "value": 0}, {
        "name": "胃灼热",
        "value": 0
    }, {"name": "稀便", "value": 0}, {"name": "低热", "value": 0}, {"name": "肠鸣音亢进", "value": 0}, {
        "name": "腿痛",
        "value": 0
    }, {"name": "眼干", "value": 0}, {"name": "大便干燥", "value": 0}, {"name": "四肢抽搐", "value": 0}, {
        "name": "皮疹",
        "value": 0
    }, {"name": "乏力", "value": 0}, {"name": "手麻", "value": 0}, {"name": "喉咙痒", "value": 0}, {
        "name": "咽痛",
        "value": 0
    }, {"name": "脱发", "value": 0}, {"name": "上腹部疼痛", "value": 0}, {"name": "头胀痛", "value": 0}, {
        "name": "胃痛",
        "value": 0
    }, {"name": "哽噎", "value": 0}, {"name": "眼睑水肿", "value": 0}, {"name": "手足脱皮", "value": 0}, {
        "name": "眼睛红肿",
        "value": 0
    }, {"name": "夜盲", "value": 0}, {"name": "反胃", "value": 0}, {"name": "尿液混浊", "value": 0}, {
        "name": "小腿酸痛",
        "value": 0
    }, {"name": "颜面水肿", "value": 0}, {"name": "抽搐", "value": 0}, {"name": "耳聋", "value": 0}, {
        "name": "耳鸣",
        "value": 0
    }, {"name": "鼻干燥", "value": 0}, {"name": "产后腹痛", "value": 0}, {"name": "肌肉痉挛", "value": 0}, {
        "name": "龟头瘙痒",
        "value": 0
    }, {"name": "心前区痛", "value": 0}, {"name": "平衡功能障碍", "value": 0}, {"name": "颈肩痛", "value": 0}, {
        "name": "大便不成形",
        "value": 0
    }, {"name": "烧心", "value": 0}, {"name": "口炎", "value": 0}, {"name": "咳痰", "value": 0}, {"name": "舌炎", "value": 0}
]

const todos = [
    {name: '已完成', value: 0},
    {name: '进行中完成', value: 0},
    {name: '未完成', value: 0},
]

const todoItems = '狄,米,贝,明,臧,计,伏,成,戴,谈,宋,茅,庞,熊,纪,舒,屈,项,祝,董,梁,杜,阮,蓝,闵,席,季,麻,强,贾,路,娄,危,江,童,颜,郭,梅,盛,林,刁,钟,徐,邱,骆,高,夏,蔡,田,樊,胡,凌,霍,虞,万,支,柯,昝,管,卢,莫,经,房,裘,缪,干,解,应,宗,丁,宣,贲,邓,郁,单,杭,洪,包,诸,左,石,崔,吉,钮,龚,程,嵇,邢,滑,裴,陆,荣,翁,荀,羊,於,惠,甄,麴,家,封,芮,羿,储,靳,汲,邴,糜,松,井,段,富,巫,乌,焦,巴,弓,牧,隗,山,谷,车,侯,宓,蓬,全,郗,班,仰,秋,仲,伊,宫,宁,仇,栾,暴,甘,钭,厉,戎,祖,武,符,刘,景,詹,束,龙,叶,幸,司,韶,郜,黎,蓟,薄,印,宿,白,怀,蒲,邰,从,鄂,索,咸,籍,赖,卓,蔺,屠,蒙,池,乔,阴,欎,胥,能,苍,双,闻,莘,党,翟,谭,贡,劳,逄,姬,申,扶,堵,冉,宰,郦,雍,舄,璩,桑,桂,濮,牛,寿,通,边,扈,燕,冀,郏,浦,尚,农,温,别,庄,晏,柴,瞿,阎,充,慕,连,茹,习,宦,艾,鱼,容,向,古,易,慎,戈,廖,庾,终,暨,居,衡,步,都,耿,满,弘,匡,国,文,寇,广,禄,阙,东,殴'
    .split(',').map(e => ([
        e + ['先生', '女士'][Math.floor(Math.random() * 2)],
        ['更新档案', '更新方案', '服务随访', '预警提示'][Math.floor(Math.random() * 4)],
        '22' + Math.ceil(Math.random() * 10000),
        ['最优', '高', '中', '低'][Math.floor(Math.random() * 4)]
    ]))

const usages = [
    ['艾灸', '弦歌堂', '服务产品', 48],
    ['推拿', '御坊堂', '服务产品', 48],
    ['艾条', '弦歌堂', '实体产品', 48],
    ['凉茶', '小广茶铺', '实体产品', 48],
    ['玉竹汤包', '小广茶铺', '实体产品', 48],
    ['辨证论治', '天一馆', '服务产品', 48],
    ['推拿正骨', '天一馆', '服务产品', 48],
]
const randomValue2 = (data, range = 168) => {
    data.forEach(u => u[3] = Math.round(Math.random() * range))
    return data
}

const randomValue1 = (data, order = false, range = 168) => data
    .map(e => ({...e, value: Math.round(Math.random() * range)}))
    .sort((a, b) => b.value - a.value)

const _institute = randomValue1(institutes, true)

const _specialists = randomValue1(specialists, true)

const supplySide = [
    {
        key: 'institutes',
        title: '从业机构',
        charts: [
            {
                title: '分类汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: _institute,
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: _institute.slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '个',
                    showValue: true
                }
            }
        ]
    },
    {
        key: 'specialists',
        title: '从业人员',
        charts: [
            {
                title: '分类汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: _specialists,
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: _specialists.slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '人',
                    showValue: true
                }
            }
        ]
    },
    {
        key: 'serviceProducts',
        title: '服务产品',
        charts: [
            {
                title: '产品形态分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(serviceProductsByCategories),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '产品病证分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(serviceProductsBySymptomsAndSyndromes),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '产品疾病分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(serviceProductsByDiseases),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
        ]
    },
    {
        key: 'physicalProducts',
        title: '实体产品',
        charts: [
            {
                title: '产品形态分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(physicalProductsByCategories),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '产品病证分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(physicalProductsBySymptomsAndSyndromes),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '产品疾病分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(physicalProductsByDiseases),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
        ]
    },
    {
        key: 'productUsage1',
        title: '产品应用情况',
        charts: [
            {
                title: '周记录',
                chartType: 'line',
                config: {
                    title: {
                        text: '产品应用情况',
                        show: false
                    },
                    legend: {
                        data: ['服务产品', '实体产品'],
                        textStyle: {
                            fill: '#aaa'
                        }
                    },
                    grid: {
                        top: '5%',
                    },
                    xAxis: {
                        name: '过去一周',
                        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            }
                        }
                    },
                    yAxis: {
                        name: '投放数量',
                        data: 'value',
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            }
                        }
                    },
                    series: [
                        {
                            name: '服务产品',
                            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
                            type: 'line',
                            fill: {
                                show: true
                            }
                        },
                        {
                            name: '实体产品',
                            data: [1400, 1800, 2400, 2000, 3000, 2200, 3485],
                            type: 'line',
                            smooth: true
                        },
                    ]
                },
            },
        ]
    },
    {
        key: 'productUsage2',
        title: '产品应用情况',
        charts: [
            {
                title: '产品排名',
                chartType: 'scrollBoard',
                config: {
                    header: ['产品', '机构', '类别', '数量'],
                    data: randomValue2(usages),
                    index: true,
                    columnWidth: [50],
                    align: ['center']
                }
            }
        ]
    }
]

const demandSide = [
    {
        key: 'customerByAgeAndGender',
        title: '人群分类',
        charts: [
            {
                title: '性别分类',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(customersByGender),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '年龄汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(customersByAge),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
        ]
    },
    {
        key: 'customerByDisease',
        title: '疾病信息',
        charts: [
            {
                title: '疾病汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(physicalProductsByDiseases),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: randomValue1(physicalProductsByDiseases, true).slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '个',
                    showValue: true
                }
            }
        ]
    },
    {
        key: 'customerByCons',
        title: '疾病信息',
        charts: [
            {
                title: '体质汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(customersByCons),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: randomValue1(customersByCons, true).slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '个',
                    showValue: true
                }
            }
        ]
    },
    {
        key: 'customerBySyndromes',
        title: '证型信息',
        charts: [
            {
                title: '证型汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(customersBySyndromes),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: randomValue1(customersBySyndromes, true).slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '个',
                    showValue: true
                }
            }
        ]
    },
    {
        key: 'customerBySymptom',
        title: '症状信息',
        charts: [
            {
                title: '分类汇总',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(customersBySymptoms),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                title: '数据概览(Top5)',
                chartType: 'capsuleChart',
                config: {
                    data: randomValue1(customersBySymptoms, true).slice(0, 5),
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                    unit: '个',
                    showValue: true
                }
            }
        ]
    },
]

const warningEvents = [
    {
        key: 'massIncidents',
        title: '群体健康事件',
        charts: [{
            title: '群体健康事件',
            chartType: 'scrollBoard',
            config: {
                header: ['客户', '机构', '预警问题', '级别', '联系方式'],
                data: [],
                index: true,
                columnWidth: [50],
                align: ['center']
            }
        }]
    },
    {
        key: 'individualIncidents',
        title: '个体预警',
        charts: [
            {
                title: '排查名单',
                chartType: 'scrollBoard',
                config: {
                    header: ['客户', '机构', '预警问题', '级别', '联系方式'],
                    data: [
                        ['张某', '健康中心', '高血压危象', '高', '18239284723'],
                        ['梁某某', '健康中心', '颈椎问题', '低', '13729387282'],
                        ['韩某', '弦歌堂', '心前区疼痛', '中', '19823246212'],
                    ],
                    index: true,
                    columnWidth: [50],
                    align: ['center']
                }
            }
        ]
    },
]

const todoEvents = [
    {
        key: 'todos',
        title: '待办事项',
        charts: [
            {
                key: 'futureTodos',
                title: '本周待办事项',
                chartType: 'line',
                config: {
                    title: {
                        show: false
                    },
                    legend: {
                        data: ['更新档案', '更新方案', '服务随访', '预警提示'],
                        textStyle: {
                            fill: '#aaa',
                            fontSize: '10px'
                        }
                    },
                    grid: {
                        top: '5%',
                        right: '0px'
                    },
                    xAxis: {
                        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            }
                        }
                    },
                    yAxis: {
                        name: '工单数量',
                        data: 'value',
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            }
                        }
                    },
                    series: ['更新档案', '更新方案', '服务随访', '预警提示']
                        .map(e => ({
                            name: e,
                            data: Array(7).fill(10).map(() => Math.ceil(Math.ceil(Math.random() * 50)) + 1),
                            type: 'line',
                            smooth: true
                        }))
                },
            },
            {
                key: 'todoPie',
                title: '待更新档案',
                chartType: 'pie',
                config: {
                    radius: '75%',
                    activeRadius: '85%',
                    data: randomValue1(todos),
                    digitalFlopStyle: {
                        fontSize: 14
                    },
                    lineWidth: 10,
                    showOriginValue: true
                }
            },
            {
                key: 'todoList',
                title: '待办事项列表',
                chartType: 'scrollBoard',
                config: {
                    header: ['客户', '事项', '工单ID', '优先级'],
                    data: todoItems,
                    index: true,
                    columnWidth: [50],
                    align: ['center']
                }
            }
        ]
    }
]

const decisionSupports = [
    {
        key: 'productDemands',
        title: '产品需求',
        charts: [
            {
                key: 'productDiseaseDemands',
                title: '产品-疾病缺口',
                config: {
                    title: {
                        show: false,
                        text: '产品-疾病缺口'
                    },
                    legend: {
                        data: ['服务产品', '实体产品'],
                        textStyle: {
                            fill: '#aaa'
                        },
                        bottom: 5
                    },
                    radar: {
                        polygon: true,
                        indicator: [
                            {name: '高血压', max: 150},
                            {name: '糖尿病', max: 150},
                            {name: '冠心病', max: 150},
                            {name: '痛风', max: 150},
                            {name: '更年期综合征', max: 150},
                            {name: '颈椎病', max: 150}
                        ],
                        center: ['50%', '45%'],
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            },
                            labelGap: 5
                        }
                    },
                    series: [
                        {
                            name: '服务产品',
                            type: 'radar',
                            data: Array(6).fill(200).map(() => Math.ceil(Math.random() * 150)),
                            label: {
                                show: false,
                                style: {
                                    fill: '#aaa'
                                }
                            },
                            animationCurve: 'easeOutBounce'
                        },
                        {
                            name: '实体产品',
                            type: 'radar',
                            data: Array(6).fill(200).map(() => Math.ceil(Math.random() * 150)),
                            label: {
                                show: false,
                                style: {
                                    fill: '#aaa'
                                }
                            },
                            animationCurve: 'easeOutBounce'
                        }
                    ]
                }
            },
            {
                key: 'productSymptomsDemands',
                title: '产品-病证缺口',
                config: {
                    title: {
                        show: false,
                        text: '产品-病证缺口'
                    },
                    legend: {
                        data: ['服务产品', '实体产品'],
                        textStyle: {
                            fill: '#aaa'
                        },
                        bottom: 5
                    },
                    radar: {
                        polygon: true,
                        indicator: [
                            {name: '肝郁脾虚证', max: 100},
                            {name: '寒湿证', max: 100},
                            {name: '阳虚质', max: 100},
                            {name: '湿热质', max: 100},
                            {name: '肩颈痛', max: 100},
                            {name: '失眠', max: 100},
                            {name: '抑郁', max: 100}
                        ],
                        axisLabel: {
                            style: {
                                fill: '#aaa'
                            },
                            labelGap: 5
                        },
                        center: ['50%', '45%'],
                    },
                    series: [
                        {
                            name: '服务产品',
                            type: 'radar',
                            data: Array(7).fill(200).map(() => Math.ceil(Math.random() * 100)),
                            label: {
                                show: false
                            },
                            animationCurve: 'easeOutBounce'
                        },
                        {
                            name: '实体产品',
                            type: 'radar',
                            data: Array(7).fill(200).map(() => Math.ceil(Math.random() * 100)),
                            label: {
                                show: false
                            },
                            animationCurve: 'easeOutBounce'
                        }
                    ]
                }
            }
        ]
    },
    {
        key: 'healthEducation',
        title: '健康科普',
        charts: [
            {
                key: 'themeEducation',
                name: '主体科普',
                chartType: 'scrollBoard',
                config: {
                    header: ['主体', '机构', '时效性', '预计人数'],
                    data: [
                        ['艾灸养生', '弦歌堂', '全年', 480],
                        ['高血压防治', '健康中心', '本季度', 230],
                        ['夏季养生', '健康中心', '本季度', 102],
                        ['中暑的防范', '健康中心', '未来15天', 78],
                    ],
                    index: true,
                    columnWidth: [50],
                    align: ['center']
                }
            }
        ]
    }
]

module.exports = {
    supplySide,
    demandSide,
    warningEvents,
    todoEvents,
    decisionSupports
}