<template>
  <div class="w-100p h-100p d-flex">
    <div id="map-container" :style="{width:chart.title?'62%':'100%'}"></div>
    <v-expand-x-transition>
      <div v-show="chart.title" class="px-4" style="width: 38%">
        <div class="pl-2">{{ chart.title }}(Top15)</div>
        <dv-capsule-chart :config="chart.title?chart.config:{data:[]}"
                          style="width:100%;height:calc(100% - 18px)"
        />
      </div>
    </v-expand-x-transition>

  </div>
</template>

<script>
import BMapGL from 'BaiduMap'
import * as mapvgl from 'mapvgl'
import * as Map from './utils/MapCommon'

export default {
  name: "HotMap",
  data: () => ({
    chart: {}
  }),
  mounted() {
    console.log('init map...')
    this.hotMap()
  },
  methods: {
    getValues() {
      const points = [
        {
          "title": "栋园",
          "uid": "fccc2033d91c0fa2c1f1cf64",
          "point": {
            "lng": 113.40052620044217,
            "lat": 23.09592806301622
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dfccc2033d91c0fa2c1f1cf64%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=fccc2033d91c0fa2c1f1cf64&output=html&source=jsapi",
          "address": "广州市海珠区夏阳大街17号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场1栋-A座",
          "uid": "bcf62d86ff68bf8cc056acd7",
          "point": {
            "lng": 113.38914934082318,
            "lat": 23.10763774373404
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dbcf62d86ff68bf8cc056acd7%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=bcf62d86ff68bf8cc056acd7&output=html&source=jsapi",
          "address": "琶洲占决新街3",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-1栋",
          "uid": "195199a1da9d351b30169bc2",
          "point": {
            "lng": 113.38962948511809,
            "lat": 23.10758422751106
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D195199a1da9d351b30169bc2%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=195199a1da9d351b30169bc2&output=html&source=jsapi",
          "address": "广东省广州市海珠区朝阳大街左二巷40号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "保利天悦25-26栋",
          "uid": "4ada62d3d523423b5d0d0231",
          "point": {
            "lng": 113.38450806574629,
            "lat": 23.106727798808883
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D4ada62d3d523423b5d0d0231%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=4ada62d3d523423b5d0d0231&output=html&source=jsapi",
          "address": "保利天悦25-26栋",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-1栋(A座)",
          "uid": "52d1fe8a5f9e7669b05c6a3f",
          "point": {
            "lng": 113.38911574419711,
            "lat": 23.107750011392593
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D52d1fe8a5f9e7669b05c6a3f%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=52d1fe8a5f9e7669b05c6a3f&output=html&source=jsapi",
          "address": "琶洲占决新街3号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-2栋(A座)",
          "uid": "14660068852ec0b078f796af",
          "point": {
            "lng": 113.38973476652383,
            "lat": 23.10702679327227
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D14660068852ec0b078f796af%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=14660068852ec0b078f796af&output=html&source=jsapi",
          "address": "琶洲占决新街7号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-7栋",
          "uid": "20b1f5bce9751727d3ff2309",
          "point": {
            "lng": 113.38806041488434,
            "lat": 23.107240942033542
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D20b1f5bce9751727d3ff2309%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=20b1f5bce9751727d3ff2309&output=html&source=jsapi",
          "address": "广州市海珠区宸悦路6号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-2栋",
          "uid": "bfc3bcfd5e0adbde583ac80b",
          "point": {
            "lng": 113.38966505801626,
            "lat": 23.106956822977374
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dbfc3bcfd5e0adbde583ac80b%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=bfc3bcfd5e0adbde583ac80b&output=html&source=jsapi",
          "address": "琶洲新村2栋(万胜围地铁站西北)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-2栋(B座)",
          "uid": "eaa4e2a72ad54589715e8053",
          "point": {
            "lng": 113.38998422596387,
            "lat": 23.106867157901867
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Deaa4e2a72ad54589715e8053%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=eaa4e2a72ad54589715e8053&output=html&source=jsapi",
          "address": "广东省广州市海珠区朝阳大街左二巷78号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false,
          "tags": [
            "商务大厦"
          ]
        },
        {
          "title": "琶洲新村-11栋",
          "uid": "004f1c649b38270142d63e9e",
          "point": {
            "lng": 113.38902223059355,
            "lat": 23.104622270370907
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D004f1c649b38270142d63e9e%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=004f1c649b38270142d63e9e&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新村11栋(万胜围地铁站西北)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村10栋-B座",
          "uid": "147dac2666e70da341afe6f9",
          "point": {
            "lng": 113.38809311320489,
            "lat": 23.105634194646964
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D147dac2666e70da341afe6f9%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=147dac2666e70da341afe6f9&output=html&source=jsapi",
          "address": "广州海珠区朝阳大街116号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村8栋-B座",
          "uid": "7daf3172f44001436ca3cc6f",
          "point": {
            "lng": 113.38677583800549,
            "lat": 23.106683755654267
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D7daf3172f44001436ca3cc6f%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=7daf3172f44001436ca3cc6f&output=html&source=jsapi",
          "address": "广州市海珠区宸悦路琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-9栋",
          "uid": "8927a9286a25a146d42ce2b6",
          "point": {
            "lng": 113.3877970317089,
            "lat": 23.10637719827328
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D8927a9286a25a146d42ce2b6%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=8927a9286a25a146d42ce2b6&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新村9栋(万胜围地铁站西北)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-18栋",
          "uid": "2ef5b93bd89ed6de38221c84",
          "point": {
            "lng": 113.39094415523151,
            "lat": 23.10545220341445
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D2ef5b93bd89ed6de38221c84%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=2ef5b93bd89ed6de38221c84&output=html&source=jsapi",
          "address": "广州市海珠区蟠龙新街6号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场1栋-B座",
          "uid": "ff46197e439a6694c33644c6",
          "point": {
            "lng": 113.38946500537926,
            "lat": 23.107518911097884
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dff46197e439a6694c33644c6%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=ff46197e439a6694c33644c6&output=html&source=jsapi",
          "address": "广东省广州市海珠区新港东琶洲新村1栋(琶洲占决新街)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-18栋(A座)",
          "uid": "40668c5a63fab4611fe99940",
          "point": {
            "lng": 113.38967134615484,
            "lat": 23.1046923249977
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D40668c5a63fab4611fe99940%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=40668c5a63fab4611fe99940&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲蟠龙新街6号",
          "city": "广州市",
          "province": "广东省",
          "phoneNumber": "18620494387",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-3栋(B座)",
          "uid": "cc7c0a18c4c3455e71455b9b",
          "point": {
            "lng": 113.39083959247017,
            "lat": 23.106278724147337
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dcc7c0a18c4c3455e71455b9b%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=cc7c0a18c4c3455e71455b9b&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新村3栋(万胜围地铁站北)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村18栋-B座",
          "uid": "1c04d752f3f43437deee8b77",
          "point": {
            "lng": 113.39075901446597,
            "lat": 23.10541705165449
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D1c04d752f3f43437deee8b77%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=1c04d752f3f43437deee8b77&output=html&source=jsapi",
          "address": "琶洲蟠龙新街10号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-4栋",
          "uid": "61245dfff08d8d37a11de9c5",
          "point": {
            "lng": 113.38912382894668,
            "lat": 23.10666131856988
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D61245dfff08d8d37a11de9c5%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=61245dfff08d8d37a11de9c5&output=html&source=jsapi",
          "address": "广州市海珠区朝阳大街116号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-5栋",
          "uid": "8916e12f17d3ab6f59bbbc06",
          "point": {
            "lng": 113.38991424796465,
            "lat": 23.1060644076326
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D8916e12f17d3ab6f59bbbc06%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=8916e12f17d3ab6f59bbbc06&output=html&source=jsapi",
          "address": "广州市海珠区琶洲蟠龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-8栋",
          "uid": "d29ab2d8415b8201a11de9e3",
          "point": {
            "lng": 113.38661683793026,
            "lat": 23.106643784401296
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dd29ab2d8415b8201a11de9e3%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=d29ab2d8415b8201a11de9e3&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新村跃龙新街7号8栋b座",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-10栋",
          "uid": "1134fd86e5515b5476e5b6b1",
          "point": {
            "lng": 113.38815123357136,
            "lat": 23.105215614439246
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D1134fd86e5515b5476e5b6b1%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=1134fd86e5515b5476e5b6b1&output=html&source=jsapi",
          "address": "广东省广州市海珠区新港东琶洲新村(新港东路北)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村5栋-B座",
          "uid": "fae9d6fe7124cf9c4107c1df",
          "point": {
            "lng": 113.39003192598643,
            "lat": 23.106113021529207
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dfae9d6fe7124cf9c4107c1df%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=fae9d6fe7124cf9c4107c1df&output=html&source=jsapi",
          "address": "万胜围地铁站D口西北200米琶洲新村5栋B座",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村9栋-A座",
          "uid": "43bb146b233f8346744ff1cc",
          "point": {
            "lng": 113.38780888934163,
            "lat": 23.106456309923235
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D43bb146b233f8346744ff1cc%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=43bb146b233f8346744ff1cc&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村9栋-b座",
          "uid": "d78f17c82236745949fd3fc9",
          "point": {
            "lng": 113.38828058956476,
            "lat": 23.106421324630066
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dd78f17c82236745949fd3fc9%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=d78f17c82236745949fd3fc9&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村10栋-A座",
          "uid": "76cdfd35f1963f57581093cf",
          "point": {
            "lng": 113.38811170812893,
            "lat": 23.10582491121883
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D76cdfd35f1963f57581093cf%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=76cdfd35f1963f57581093cf&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-12栋",
          "uid": "c2a07901fd0991e62cb9088c",
          "point": {
            "lng": 113.38835110654726,
            "lat": 23.104683682436015
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dc2a07901fd0991e62cb9088c%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=c2a07901fd0991e62cb9088c&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲蟠龙新街5",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村3栋-A座",
          "uid": "b8bb5caf437c48606240a9e8",
          "point": {
            "lng": 113.39066595001515,
            "lat": 23.106384261815368
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Db8bb5caf437c48606240a9e8%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=b8bb5caf437c48606240a9e8&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-6栋",
          "uid": "411b340e38d174b2ad31e5fa",
          "point": {
            "lng": 113.38955851898282,
            "lat": 23.105885408618807
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D411b340e38d174b2ad31e5fa%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=411b340e38d174b2ad31e5fa&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新马路琶洲新村(保利·琶洲湾附近)",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村10-12栋停车场",
          "uid": "cffa02c58a70de04c8213d31",
          "point": {
            "lng": 113.38802906402205,
            "lat": 23.10568970607828
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dcffa02c58a70de04c8213d31%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=cffa02c58a70de04c8213d31&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村3栋停车场-出入口",
          "uid": "1300028a72259263d92f28f3",
          "point": {
            "lng": 113.3906424144108,
            "lat": 23.105930199945274
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D1300028a72259263d92f28f3%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=1300028a72259263d92f28f3&output=html&source=jsapi",
          "address": "琶洲蟠龙新街25号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-9栋停车场",
          "uid": "86d5aa1a26a908f90bccdd30",
          "point": {
            "lng": 113.38770208081652,
            "lat": 23.106594755197218
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D86d5aa1a26a908f90bccdd30%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=86d5aa1a26a908f90bccdd30&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场1栋B座-南门",
          "uid": "00ff4133413f2addae0457c9",
          "point": {
            "lng": 113.38946500537926,
            "lat": 23.107509936321037
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D00ff4133413f2addae0457c9%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=00ff4133413f2addae0457c9&output=html&source=jsapi",
          "address": "琶洲占决新街5",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村7-9栋停车场停车场-出入口",
          "uid": "a729592f696107019c09f77a",
          "point": {
            "lng": 113.38729487892898,
            "lat": 23.106481406256126
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Da729592f696107019c09f77a%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=a729592f696107019c09f77a&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲新马路东150米",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村7-9栋停车场",
          "uid": "627c85f505c1a6b0abf7290e",
          "point": {
            "lng": 113.38770208081652,
            "lat": 23.106594755197218
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D627c85f505c1a6b0abf7290e%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=627c85f505c1a6b0abf7290e&output=html&source=jsapi",
          "address": "龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场18栋A座(西门)",
          "uid": "06e71eafb846cf6e0b1cc718",
          "point": {
            "lng": 113.38950956133256,
            "lat": 23.104893098195458
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D06e71eafb846cf6e0b1cc718%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=06e71eafb846cf6e0b1cc718&output=html&source=jsapi",
          "address": "琶洲蟠龙新街6",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "菜鸟驿站(广州海珠琶洲新村3栋店)",
          "uid": "5a6b70b3e1e22c7e96ca07db",
          "point": {
            "lng": 113.39044334990986,
            "lat": 23.10653542152605
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D5a6b70b3e1e22c7e96ca07db%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=5a6b70b3e1e22c7e96ca07db&output=html&source=jsapi",
          "address": "广州市海珠区暄悦西街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村7-9栋停车场(出入口)",
          "uid": "4be7d6e201fd65388f55ec71",
          "point": {
            "lng": 113.38744300950752,
            "lat": 23.10644991118859
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D4be7d6e201fd65388f55ec71%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=4be7d6e201fd65388f55ec71&output=html&source=jsapi",
          "address": "广州市",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村6栋地下停车场-出入口",
          "uid": "b4db64e4791356aee60738b4",
          "point": {
            "lng": 113.38903453737903,
            "lat": 23.10533428293472
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Db4db64e4791356aee60738b4%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=b4db64e4791356aee60738b4&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲蟠龙新街7",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场18栋-北门",
          "uid": "e985fa4227c23578715e8015",
          "point": {
            "lng": 113.39088208232079,
            "lat": 23.10561134188032
          },
          "url": "https://map.baidu.com?s=inf%26uid%3De985fa4227c23578715e8015%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=e985fa4227c23578715e8015&output=html&source=jsapi",
          "address": "广州市海珠区琶洲蟠龙新街10号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲商业广场2栋B座(南门)",
          "uid": "e1aba6cce46a0ddcf8f0cb68",
          "point": {
            "lng": 113.39012786501488,
            "lat": 23.106869152306434
          },
          "url": "https://map.baidu.com?s=inf%26uid%3De1aba6cce46a0ddcf8f0cb68%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=e1aba6cce46a0ddcf8f0cb68&output=html&source=jsapi",
          "address": "琶洲村新马路与琶洲蟠龙新街交叉口西北200米",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村3栋地下停车场-出入口",
          "uid": "e3a872abec81a3eaede4a570",
          "point": {
            "lng": 113.39070529579648,
            "lat": 23.105899037150174
          },
          "url": "https://map.baidu.com?s=inf%26uid%3De3a872abec81a3eaede4a570%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=e3a872abec81a3eaede4a570&output=html&source=jsapi",
          "address": "万胜围地铁站D口北150米",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村-南门",
          "uid": "2ab21f4d7d7d5c33cd04ef09",
          "point": {
            "lng": 113.39003408191965,
            "lat": 23.106871811512463
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D2ab21f4d7d7d5c33cd04ef09%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=2ab21f4d7d7d5c33cd04ef09&output=html&source=jsapi",
          "address": "广东省广州市海珠区琶洲占决新街7",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "中国民生银行(琶洲支行)",
          "uid": "055ead0d820f6fcfc91b95cd",
          "point": {
            "lng": 113.38593601218449,
            "lat": 23.10764198181911
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D055ead0d820f6fcfc91b95cd%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=055ead0d820f6fcfc91b95cd&output=html&source=jsapi",
          "address": "宸悦路5号保利天悦27栋",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false,
          "tags": [
            "金融",
            "银行",
            "中国民生银行"
          ]
        },
        {
          "title": "琶洲新村79栋停车场-出入口",
          "uid": "c119ff4982003e8026d2bbe6",
          "point": {
            "lng": 113.3872924535041,
            "lat": 23.1064788301427
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dc119ff4982003e8026d2bbe6%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=c119ff4982003e8026d2bbe6&output=html&source=jsapi",
          "address": "广州市海珠区琶洲跃龙新街琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "栋方股份",
          "uid": "cd6fba393a1721f85cbe869d",
          "point": {
            "lng": 113.23879132393493,
            "lat": 23.349696016350254
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dcd6fba393a1721f85cbe869d%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=cd6fba393a1721f85cbe869d&output=html&source=jsapi",
          "address": "广州市花都区华兴南路与镜湖大道南交叉路口往北约70米",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶洲新村18号楼-地下停车场",
          "uid": "3e653de169a426eaca2915ea",
          "point": {
            "lng": 113.39024617185049,
            "lat": 23.10524802391282
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D3e653de169a426eaca2915ea%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=3e653de169a426eaca2915ea&output=html&source=jsapi",
          "address": "琶洲蟠龙新街8号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "广州栋星生物科技有限公司",
          "uid": "fc0d1005ad5bfac505026f82",
          "point": {
            "lng": 113.21282014385288,
            "lat": 23.340326602034263
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dfc0d1005ad5bfac505026f82%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=fc0d1005ad5bfac505026f82&output=html&source=jsapi",
          "address": "雄郭东路223号",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        },
        {
          "title": "琶江月",
          "uid": "fcf6232de70650796e20b99b",
          "point": {
            "lng": 113.3895010274302,
            "lat": 23.107690013440322
          },
          "url": "https://map.baidu.com?s=inf%26uid%3Dfcf6232de70650796e20b99b%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=fcf6232de70650796e20b99b&output=html&source=jsapi",
          "address": "琶洲新村1栋A区4层",
          "city": "广州市",
          "province": "广东省",
          "phoneNumber": "(020)89119980",
          "type": 0,
          "isAccurate": false,
          "tags": [
            "生活服务"
          ]
        },
        {
          "title": "琶洲新村8栋-B座",
          "uid": "7daf3172f44001436ca3cc6f",
          "point": {
            "lng": 113.38677583800549,
            "lat": 23.106683755654267
          },
          "url": "https://map.baidu.com?s=inf%26uid%3D7daf3172f44001436ca3cc6f%26c%3D257&i=0&sr=1",
          "detailUrl": "//api.map.baidu.com/place/detail?uid=7daf3172f44001436ca3cc6f&output=html&source=jsapi",
          "address": "广州市海珠区宸悦路琶洲新村",
          "city": "广州市",
          "province": "广东省",
          "type": 0,
          "isAccurate": false
        }
      ]
      return points.map(e => ({
        property: e,
        point: [e.point.lng, e.point.lat, Math.random() * 50]
      }))
    },
    hotMap() {
      let map = Map.initMap({
        center: [113.38879, 23.10599],
        zoom: 18,
        tilt: 65,
        style: Map.purpleStyle
      });
      let polygon = new BMapGL.Polygon(
          [
            [113.38926, 23.10803],
            [113.39156, 23.10593],
            [113.39117, 23.10414],
            [113.38358, 23.10437],
            [113.38369, 23.10578],
          ].map(e => new BMapGL.Point(...e)),
          {
            strokeColor: 'blue',
            strokeWeight: 2,
            strokeOpacity: 0.5,
            fillColor: 'blue',
            fillOpacity: 0.2
          })
      map.addOverlay(polygon);

      let view = new mapvgl.View({
        map: map
      });
      let rs = this.getValues();
      let data = [];
      for (let i = 0; i < rs.length; i++) {
        let {property, point: item} = rs[i];
        data.push({
          geometry: {
            type: 'Point',
            coordinates: [item[0], item[1]]
          },
          properties: {
            ...property,
            count: item[2]
          }
        });
      }

      let heatmap = new mapvgl.HeatGridLayer({
        max: 80, // 最大阈值
        min: 10, // 最小阈值
        // color: function() {
        //     return 'rgb(200, 255, 0)';
        // },
        gridSize: 40,
        // style: 'normal',
        gradient: { // 对应比例渐变色
          0.25: 'rgba(89, 233, 179, 1)',
          0.55: 'rgba(182, 243, 147, 1)',
          0.85: 'rgba(254, 255, 140, 1)',
          0.9: 'rgba(217, 29, 28, 1)',
        },
        // textOptions: {
        //     show: true,
        //     color: '#f00'
        // },
        riseTime: 1800, // 楼块初始化升起时间
        maxHeight: 100, // 最大高度
        minHeight: 20, // 最小高度
        enablePicked: true, // 是否可以拾取
        selectedIndex: -1, // 选中数据项索引
        selectedColor: '#aabbcc', // 选中项颜色
        autoSelect: true, // 根据鼠标位置来自动设置选中项
        onClick: (e) => { // 点击事件
          console.log(e);
          let chart = {
            title: '数据概览(Top5)',
            chartType: 'capsuleChart',
            config: {
              data: [],
              colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
              unit: '',
              showValue: true
            }
          }
          chart.title = e.dataItem.properties.title
          chart.config.data = [
            {key: '', name: '高血压', value: 0},
            {key: '', name: '糖尿病', value: 0},
            {key: '', name: '冠心病', value: 0},
            {key: '', name: '颈椎病', value: 0},
            {key: '', name: '感冒', value: 0},
            {key: '', name: '更年期综合征', value: 0},
            {key: '', name: '阳虚证', value: 0},
            {key: '', name: '寒湿证', value: 0},
            {key: '', name: '气虚体质', value: 0},
            {key: '', name: '肝气瘀滞证', value: 0},
            {key: '', name: '颈肩痛', value: 0},
            {key: '', name: '失眠', value: 0},
            {key: '', name: '外感诸症', value: 0},
            {key: '', name: '头痛', value: 0},
            {key: '', name: '大便溏泄', value: 0},
            {key: '', name: '情志不遂', value: 0},
          ].map(e => ({...e, value: Math.round(Math.random() * 100)}))
              .sort((a, b) => b.value - a.value)
              .slice(0, 15)
          this.chart = chart
        },
      });
      view.addLayer(heatmap);
      heatmap.setData(data);
    }
  }
}
</script>

<style scoped>
#map-container {
  overflow: hidden;
  position: relative;
  background: #102030;
  text-align: left;
  touch-action: none;
  width: 100%;
  height: 100%;
}
</style>